import liff from '@line/liff';
import type {
  HeadersFunction,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  TypedResponse } from
'@remix-run/cloudflare';
import { json } from '@remix-run/cloudflare';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useNavigation,
  useRouteError,
  useSearchParams } from
'@remix-run/react';
import Cookies from 'js-cookie';
import { useEffect, type ReactElement } from 'react';
import slickThemeStyles from 'slick-carousel/slick/slick-theme.css';
import slickStyles from 'slick-carousel/slick/slick.css';
import { Provider, useQuery } from 'urql';
import { NotFound, StandardError } from './components/errors';
import { ErrorLayout } from './components/layouts';
import { GaScriptTag, GtmNoscriptTag, GtmScriptTag } from './components/root/AnalyticsHead';
import { ErrorFlash, Flash, LineFavoriteModal, ProgressBar, Toast } from './components/shared';
import { userQueryDocument } from './gql/queries';
import { useAnalytics } from './hooks';
import { getEnvironmentVariables } from './infrastructure/environmentVariables';
import { noCache } from './infrastructure/header';
import {
  AuthProvider,
  CarListDisplayTypeProvider,
  ClientEnvProvider,
  DeliveryAddressProvider,
  ErrorFlashProvider,
  FlashProvider,
  InfiniteScrollProvider,
  LiffProvider,
  LineFavoriteModalProvider,
  PopupProvider,
  ToastProvider,
  WordPressProvider } from
'./providers';
import styles from './styles/common.css';
import tailwind from './tailwind.css';
import { type EnvironmentalVariables } from './types/environmentVariables';
import { baseMetaTags } from './utils/meta';
import type { ParsedUrl } from 'query-string';
import queryString from 'query-string';
import { useGraphqlClient } from './hooks/useGraphqlClient';
import * as CSentry from '@sentry/cloudflare';
import * as Sentry from '@sentry/remix';

type LoadData = {
  env: EnvironmentalVariables;
  requestedUrl: ParsedUrl;
};

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
{ rel: 'stylesheet', href: styles },
{ rel: 'stylesheet', href: tailwind },
{ rel: 'stylesheet', href: slickStyles },
{ rel: 'stylesheet', href: slickThemeStyles },
{
  rel: 'icon',
  type: 'image-icon',
  href: '/images/meta/appleicon.png'
},
{
  rel: 'apple-touch-icon',
  sizes: '180x180',
  type: 'image/png',
  href: '/images/meta/appleicon.png'
}];


export const headers: HeadersFunction = () => {
  return {
    'Cache-Control': noCache
  };
};

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  const env = data?.env;
  return baseMetaTags({ env: env, location: location });
};

export const loader = ({ context, request }: LoaderFunctionArgs): TypedResponse<LoadData> => {
  const env = getEnvironmentVariables(context);
  CSentry.captureException(new Error('これはサーバーエラー'));
  return json<LoadData>({ env: env, requestedUrl: queryString.parseUrl(request.url) });
};

const Providers = ({
  data,
  isMiniapp,
  children




}: {data: LoadData;isMiniapp: boolean;children: React.ReactNode;}) => {
  const { graphqlClient } = useGraphqlClient({ baseUrl: data.env.graphqlBaseUrl, requestedUrl: data.requestedUrl });
  return (
    <ClientEnvProvider {...data.env}>
      <Provider value={graphqlClient}>
        <FlashProvider>
          <ErrorFlashProvider>
            <LiffProvider isMiniApp={isMiniapp}>
              <DeliveryAddressProvider>
                <AuthProvider>
                  <LineFavoriteModalProvider>
                    <ToastProvider>
                      <InfiniteScrollProvider>
                        <PopupProvider>
                          <WordPressProvider>
                            <CarListDisplayTypeProvider>{children}</CarListDisplayTypeProvider>
                          </WordPressProvider>
                        </PopupProvider>
                      </InfiniteScrollProvider>
                    </ToastProvider>
                  </LineFavoriteModalProvider>
                </AuthProvider>
              </DeliveryAddressProvider>
            </LiffProvider>
          </ErrorFlashProvider>
        </FlashProvider>
      </Provider>
    </ClientEnvProvider>);

};

const InitializeAnalytics = () => {
  const [{ data: userData }] = useQuery({ query: userQueryDocument });
  const { initializeUser, setCustomeUserId } = useAnalytics();
  const { sendPageEvent } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    setCustomeUserId();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userData?.viewer) return;
    initializeUser(userData.viewer.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    sendPageEvent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // 販売の広告経由の計測のために追加
  // 計測のための実装はあまりしたくないので、いい方法があれば削除したい
  useEffect(() => {
    if (location.pathname !== '/search') return;

    const querySearch = location.search;
    if (querySearch.includes('utm_source')) {
      Cookies.set('search-query-parameter', encodeURIComponent(querySearch.slice(1)), { expires: 1 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default function App() {
  const data = useLoaderData<typeof loader>();
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();
  const isMiniapp = searchParams.get('is_miniapp') === 'true';

  useEffect(() => {
    liff.init({ liffId: isMiniapp ? data.env.miniappLiffId : data.env.liffId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Sentry.init({
      dsn: 'https://2e3fe5c4d07ad8329e8af94948577744@o4508113529274368.ingest.us.sentry.io/4508291852206080',
      environment: data.env.environment,
      tracesSampleRate: 0.1,
      integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation
      })]

    });
  }, [data.env]);

  return (
    <html lang='ja'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        {data.env.gaTrackingId && <GaScriptTag gaTrackingId={data.env.gaTrackingId} />}
        {data.env.gtmId && <GtmScriptTag gtmId={data.env.gtmId} />}
        <Meta />
        <Links />
      </head>
      <body>
        {data.env.gtmId && <GtmNoscriptTag gtmId={data.env.gtmId} />}
        <div id='root'>
          <Providers data={data} isMiniapp={isMiniapp}>
            <InitializeAnalytics />
            {navigation.state === 'loading' && <ProgressBar />}
            <Outlet />
            <ScrollRestoration />
            <LiveReload />
            <LineFavoriteModal />
            <Toast />
            <Flash />
            <ErrorFlash />
          </Providers>
        </div>
        <Scripts />
      </body>
    </html>);

}

export function ErrorBoundary(): ReactElement {
  const error = useRouteError();
  CSentry.captureException(error);
  return (
    <html lang='ja'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body>
        <ErrorLayout>
          {isRouteErrorResponse(error) && error.status === 404 ? <NotFound /> : <StandardError />}
        </ErrorLayout>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}